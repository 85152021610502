@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    width: 100%;
    height: 100%;
    font-family: "Inter Semibold", serif;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.cookieConsent__Button {
    background: white;
}

@media screen and (max-width: 428px) {
    .cookieConsent__Button {
        width: 100%;
    }
    .cookieConsent__Right{
        display: block;
        overflow: hidden;
    }
}

.cookieConsent__Button--Close {
    background: black;
}
